var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-edit-partner-settings"},[_c('loading-screen',{attrs:{"is-loading":_vm.isSaving}}),_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-title"},[_vm._v("Configure Partner Integration")]),_c('div',{staticClass:"actions"},[_c('a-button',{staticClass:"button-margin-left btn-rounded",attrs:{"icon":"arrow-left","size":"large","type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_vm._v("Back")])],1)]),_c('organisation-details-panel'),_c('div',{staticClass:"card basic-details flex"},[_c('div',{staticClass:"left"},[_c('api-client-thumbnail',{attrs:{"tenant-id":_vm.tenantId,"size":"large","api-client":_vm.apiClient}})],1),_c('div',{staticClass:"right"},[_c('h3',[_vm._v(_vm._s(_vm.apiClient.displayName))]),_vm._v(" "+_vm._s(_vm.apiClient.headline)+" ")])]),(_vm.isLoadingCombined)?_c('div',{staticClass:"card loading"},[_c('a-spin')],1):_vm._e(),(!_vm.isLoadingCombined && _vm.apiGrant && _vm.linkTarget)?_c('div',[_c('div',{staticClass:"card settings"},[_c('h3',[_vm._v("Settings")]),_vm._l((_vm.controls),function(control,i){return _c('div',{key:i},[(
            control['$t'] ==
            'Soteria.Models.LinkTargetStringControl, Soteria.Models'
          )?_c('link-target-string-control',{attrs:{"control":control,"params":_vm.params}}):_vm._e(),(
            control['$t'] ==
            'Soteria.Models.LinkTargetLookupControl, Soteria.Models'
          )?_c('link-target-lookup-control',{attrs:{"control":control,"params":_vm.params,"tenant-id":_vm.tenantId,"org-id":_vm.selectedOrganisation.id,"api-client-id":_vm.apiClient.id}}):_vm._e()],1)})],2),((_vm.linkTarget.organisationKeyName || _vm.linkTarget.secretName))?_c('div',{staticClass:"card authentication"},[_c('h3',[_vm._v("Authentication")]),(_vm.linkTarget.organisationKeyName)?_c('a-form-item',{attrs:{"label":_vm.linkTarget.organisationKeyName}},[_c('a-input',{attrs:{"size":"large"},model:{value:(_vm.organisationKey),callback:function ($$v) {_vm.organisationKey=$$v},expression:"organisationKey"}})],1):_vm._e(),(_vm.linkTarget.secretName)?_c('a-form-item',{attrs:{"label":_vm.linkTarget.secretName}},[_c('a-input-password',{attrs:{"size":"large"},model:{value:(_vm.secret),callback:function ($$v) {_vm.secret=$$v},expression:"secret"}})],1):_vm._e()],1):_vm._e(),_c('a-button',{staticClass:"btn-rounded save-button",attrs:{"size":"large","type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v("Save")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }