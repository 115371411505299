<template>
  <div class="admin-edit-partner-settings">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Configure Partner Integration</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <organisation-details-panel></organisation-details-panel>

    <!-- Card - Basic Details -->
    <div class="card basic-details flex">
      <div class="left">
        <api-client-thumbnail
          :tenant-id="tenantId"
          size="large"
          :api-client="apiClient"
        ></api-client-thumbnail>
      </div>
      <div class="right">
        <h3>{{ apiClient.displayName }}</h3>

        {{ apiClient.headline }}
      </div>
    </div>
    <!-- / Card - Basic Details -->

    <!-- Loading -->
    <div v-if="isLoadingCombined" class="card loading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Link Target Settings -->
    <div v-if="!isLoadingCombined && apiGrant && linkTarget">
      <!-- Settings -->
      <div class="card settings">
        <h3>Settings</h3>

        <!-- Controls -->
        <div v-for="(control, i) in controls" :key="i">
          <link-target-string-control
            v-if="
              control['$t'] ==
              'Soteria.Models.LinkTargetStringControl, Soteria.Models'
            "
            :control="control"
            :params="params"
          ></link-target-string-control>

          <link-target-lookup-control
            v-if="
              control['$t'] ==
              'Soteria.Models.LinkTargetLookupControl, Soteria.Models'
            "
            :control="control"
            :params="params"
            :tenant-id="tenantId"
            :org-id="selectedOrganisation.id"
            :api-client-id="apiClient.id"
          >
          </link-target-lookup-control>
        </div>
        <!-- / Controls -->
      </div>
      <!-- / Settings -->

      <!-- Authentication -->
      <div class="card authentication" v-if="(linkTarget.organisationKeyName || linkTarget.secretName)">
        <h3>Authentication</h3>
        <a-form-item
          v-if="linkTarget.organisationKeyName"
          :label="linkTarget.organisationKeyName"
        >
          <a-input v-model="organisationKey" size="large"></a-input>
        </a-form-item>

        <a-form-item
          v-if="linkTarget.secretName"
          :label="linkTarget.secretName"
        >
          <a-input-password v-model="secret" size="large"></a-input-password>
        </a-form-item>
      </div>
      <!-- / Authentication -->

      <a-button
        @click.prevent="save"
        class="btn-rounded save-button"
        size="large"
        type="primary"
        >Save</a-button
      >
    </div>
    <!-- / Link Target Settings -->
  </div>
</template>

<script>
const _ = require("lodash");
import { mapGetters } from "vuex";
import ApiClientThumbnail from "../../../Incidents/Show/Tabs/Escalate/ShareWith/ApiClientCard/ApiClientThumbnail.vue";
import organisationHelpers from "../../../../helpers/organisations";
// import apiClientHelpers from "../../../../helpers/api-clients";
import apiGrants from "../../../../api/api-grants";
import apiClientsApi from "../../../../api/api-clients";
import Dates from "../../../../mixins/Dates";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import LinkTargetStringControl from "./EditSettings/LinkTargetStringControl.vue";
import LinkTargetLookupControl from "./EditSettings/LinkTargetLookupControl.vue";
import OrganisationDetailsPanel from './OrganisationDetailsPanel.vue';

export default {
  name: "EditSettings",
  mixins: [Dates],
  data() {
    return {
      isLoading: false,
      apiGrant: null,
      windowWidth: window.innerWidth,

      isSavingSettings: false,
      isSavingSecret: false,

      isLoadingLinkTarget: false,
      linkTarget: null,

      isLoadingApiGrantSecret: false,
      apiGrantSecret: null,

      isLoadingApiGrantSettings: false,
      apiGrantSettings: null,

      params: {},

      organisationKey: null,
      secret: null,
    };
  },
  created() {
    this.getActiveApiGrant();
    this.getApiGrantSettings();
    this.getLinkTargetIfExists();

    if (!this.canEditApiClient) {
      this.goBack();
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  components: {
    ApiClientThumbnail,
    LoadingScreen,
    LinkTargetStringControl,
    LinkTargetLookupControl,
    OrganisationDetailsPanel,
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
    }),

    ...mapGetters("adminApiClients", {
      partners: "partners",
      apiClients: "apiClients",
    }),

    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      myPresenceId: "myPresenceId",
      membership: "membership",
    }),

    ...mapGetters("permissions", {
      canEditApiClient: "canEditApiClient"
    }),

    isSaving() {
      return this.isSavingSettings || this.isSavingSecret;
    },

    isLoadingCombined() {
      return (
        this.isLoading ||
        this.isLoadingLinkTarget ||
        this.isLoadingApiGrantSecret ||
        this.isLoadingApiGrantSettings
      );
    },

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },

    partner() {
      return _.find(this.partners, (partner) => {
        return partner.id == this.$route.params.id;
      });
    },

    apiClient() {
      if (this.partner) {
        return _.find(this.apiClients, { id: this.partner.apiClientId });
      }
      return null;
    },

    tenantId() {
      if (!this.partner) {
        return null;
      }

      let organisation = _.find(this.organisations, (org) => {
        return org.ownerId == this.partner.id;
      });

      if (organisation) {
        return organisationHelpers.getOrganisationTenantId(organisation);
      }

      return this.partner.id;
    },

    controls() {
      if (this.linkTarget && this.linkTarget.settingParameters) {
        return this.linkTarget.settingParameters.controls;
      }
      return [];
    },
  },
  methods: {
    goBack() {
      this.$router.push("/admin/integration/partners/" + this.partner.id);
    },

    getActiveApiGrant() {
      let vm = this;

      if (!vm.apiClient || !vm.selectedOrganisation) {
        return false;
      }

      vm.isLoading = true;
      apiGrants
        .getActiveApiGrant(
          this.tenantId,
          this.selectedOrganisation.id,
          this.apiClient.id
        )
        .then((r) => {
          vm.isLoading = false;
          vm.apiGrant = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          //   vm.$notification.error("Cannot load active API grant");
        });
    },

    async getLinkTargetIfExists() {
      let vm = this;
      if (this.apiClient && this.apiClient.linkTargetId) {
        vm.isLoadingLinkTarget = true;

        try {
          vm.linkTarget = await apiClientsApi
            .getApiClientLinkTarget(vm.apiClient.linkTargetId)
            .then((r) => r.data);
        } catch (e) {
          console.log(e);
        }

        if (vm.linkTarget.organisationKeyName || vm.linkTarget.secretName) {
          vm.getApiGrantSecret();
        }

        vm.isLoadingLinkTarget = false;
      }
    },

    async getApiGrantSecret() {
      let vm = this;
      vm.isLoadingApiGrantSecret = true;

      try {
        vm.apiGrantSecret = await apiGrants
          .getApiGrantSecretStatus(
            vm.tenantId,
            vm.selectedOrganisation.id,
            vm.apiClient.id
          )
          .then((r) => r.data);
      } catch (e) {
        console.log(e);
      }

      vm.isLoadingApiGrantSecret = false;
    },

    async getApiGrantSettings() {
      let vm = this;
      vm.apiGrantSettings = null;
      vm.isLoadingApiGrantSettings = true;

      try {
        vm.apiGrantSettings = await apiGrants
          .getApiGrantSettings(
            vm.tenantId,
            vm.selectedOrganisation.id,
            vm.apiClient.id
          )
          .then((r) => r.data);
      } catch (e) {
        console.log(e);
      }

      if (vm.apiGrantSettings && vm.apiGrantSettings.settings) {
        vm.params = {
          ...vm.apiGrantSettings.settings,
        };
      }

      vm.isLoadingApiGrantSettings = false;
    },

    async save() {
      let vm = this;
      vm.isSavingSettings = true;
      apiGrants
        .setApiGrantSettings(
          this.tenantId,
          this.selectedOrganisation.id,
          this.apiClient.id,
          {
            settings: this.params,
          }
        )
        .then(() => {
          vm.isSavingSettings = false;
          vm.$message.success("Settings saved successfully");

          if (this.organisationKey || this.secret) {
            vm.isSavingSecret = true;
            apiGrants
              .setApiGrantSecret(
                vm.tenantId,
                vm.selectedOrganisation.id,
                vm.apiClient.id,
                {
                  organisationKey: vm.organisationKey,
                  secret: vm.secret
                }
              )
              .then(() => {
                vm.isSavingSecret = false;
                // vm.$message.success("Settings saved successfully");
                vm.goBack();
              })
              .catch((e) => {
                console.log(e);
                vm.isSavingSecret = false;
                vm.$message.success("Error saving authentication details");
                vm.goBack();
              });
          } else {
            vm.goBack();
          }
        })
        .catch((e) => {
          console.log(e);
          vm.isSavingSettings = false;
          vm.$message.error("Error saving settings");
        });
    },
  },
};
</script>

<style lang="scss">
.admin-edit-partner-settings {
  padding-bottom: 40px;

  .button-margin-left {
    margin-left: 10px;
  }

  .mr-2 {
    margin-right: 6px;
  }

  .card {
    background: #fff;
    padding: 25px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 20px;

    &.loading {
      text-align: center;
    }

    &.flex {
      display: flex;
    }

    .left {
      flex-shrink: 1;
      padding-right: 30px;
    }

    .right {
      flex-grow: 1;
    }

    .ant-avatar-lg {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }

    h3 {
      margin-bottom: 15px;
    }

    .edit-link-target {
      margin-top: 20px;
    }

    &.basic-details {
      h3 {
        margin-bottom: 9px;
      }
    }

    &.granted-permissions {
      ul {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &.support-information {
      .support-information-buttons {
        padding-top: 10px;
        .ant-btn {
          margin-right: 15px;
        }
      }
    }
  }

  .api-grant-details {
    .col-inner {
      padding-bottom: 20px;
    }

    .card,
    .col-inner {
      height: 100%;
    }
  }
}
</style>