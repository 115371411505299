<template>
  <div>
    <a-form-item :label="control['$v']['label']" :required="isRequired">
      <a-input
        size="large"
        :placeholder="placeholder"
        v-model="params[control['$v']['name']]"
      ></a-input>
    </a-form-item>
  </div>
</template>

<script>
export default {
  props: ["params", "control"],
  created() {
    if (this.placeholder) {
      this.params[this.control["$v"]["name"]] = this.placeholder;
    }
  },
  computed: {
    placeholder() {
      if (this.control["$v"]["placeholder"]) {
        return this.control["$v"]["placeholder"];
      }
      return "";
    },
    isRequired() {
      return (
        this.control["$v"]["required"] && this.control["$v"]["required"] == true
      );
    },
  },
};
</script>

<style>
</style>